<template>
    <!-- 场地路由 -->
    <div class="field-list-box">
        <banner-carousel></banner-carousel>
        <field-type></field-type>
        <!-- 过滤条件 -->
        <filter-share
            :filter-hotwords="hotwords"
            :filter-clazz="clazz"
            :filter-price="price"
            :remote-methods="getFieldList">
        </filter-share>
        <!-- 列表 -->
        <div class="field-list">
            <div class="container">
                <el-row :gutter="20">
                    <el-col 
                        :span="6"
                        v-for="item in goodsList"
                        :key="item.id">
                        <field-list-item
                            :item-data="item">
                        </field-list-item>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 分页器 -->
        <div class="page-box container">
            <el-pagination
                :page-count="parseInt(page.page_count)"
                :current-page="parseInt(page.current_page)"
                layout="prev, pager, next, jumper"
                @current-change="handlePage"
                background>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    computed: mapState({
        goodsList: state => state.goodsList.list,
        page: state => state.goodsList.page,
        hotwords: state => state.goodsList.hotwords,
        clazz: state => state.goodsList.clazz,
        price: state => state.goodsList.price
    }),
    components:{
        bannerCarousel: () => import('@/components/bannerCarousel'),
        fieldType: () => import("@/components/fieldType"),
        filterShare: () => import("@/components/filterShare"),
        fieldListItem: () => import('@/components/fieldListItem'),
    },
    methods: {
        ...mapMutations([
            "clearList",
        ]),
        ...mapActions([
            "getFieldList",
            "getFieldBanner",
            "getFieldCondition"
        ]),
        handlePage(arg){
            let search = sessionStorage.getItem("search") || "",
            style = sessionStorage.getItem("sift_style") || "0",
            clazz = sessionStorage.getItem("sift_type") || "0",
            price = sessionStorage.getItem("sift_price") || "0",
            area = sessionStorage.getItem("sift_area") || "";
            this.getFieldList({
                search, 
                area,
                style, 
                clazz, 
                price,
                page: arg
            });
        },
    },
    beforeRouteEnter (to, from, next) {
        let search = sessionStorage.getItem("search") || "",
        style = sessionStorage.getItem("sift_style") || "0",
        clazz = sessionStorage.getItem("sift_type") || "0",
        price = sessionStorage.getItem("sift_price") || "0",
        area = sessionStorage.getItem("sift_area") || "";
        next(vm => {
            vm.getFieldList({
                search, 
                style, 
                clazz, 
                price,
                area
            });
            vm.getFieldBanner();
            vm.getFieldCondition();
        });
    },
    beforeRouteLeave (to, from, next) {
        sessionStorage.removeItem("search");
        sessionStorage.removeItem("sift_area");
        sessionStorage.removeItem("sift_style");
        sessionStorage.removeItem("sift_type");
        sessionStorage.removeItem("sift_price");
        this.clearList();
        next();
    },
}
</script>

<style scoped>
.field-list-box{
    background-color: #fafafa;
    /* background-image: url('../assets/images/bg.jpg'); */
    background-size: cover;
}
/* 商品 */
.field-list{
    padding: 30px 0;
}
.field-list .el-col{
    margin-bottom: 15px;
}
</style>
